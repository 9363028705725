import React from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

// Components
import { Draggable } from "react-beautiful-dnd";
import {
  useTheme,
  Button,
  IconButton,
  Checkbox,
  TextField,
} from "@mui/material";
import {
  FaFileAlt,
  FaSyncAlt,
  FaTimes,
  FaEye,
  FaCheckCircle,
  FaEllipsisH,
  FaRegCircle,
  FaHourglassHalf,
} from "react-icons/fa";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Own api
import {
  tagColors,
  Devices,
  ScreenNames,
  recurrencePatternEnum,
} from "../../../api/constants";

const Goal = ({
  item,
  index,
  itemToHighlight,
  date,
  goalBoxPressedHandler,
  isSubmitted,
  isGoal,
  navigateToTargetsScreen,
  setItemToCancelEdit,
  itemToCancelEdit,
  setInputHoveredId,
  inputHoveredId,
  setAllowNavigation,
  setSortedGoals,
  sortedGoals,
  setGoalToEdit,
  setIsRepeatPatternPickerOverlayVisible,
  saveChanges,
  cancelChanges,
  downHandler,
  changeCurrentScreen,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentDevice = useSelector((state) => state.local.currentDevice);

  const handleCheck = (itemPressed) => {
    goalBoxPressedHandler(itemPressed);
  };

  /////////////////// Render ///////////////////
  const getItemStyle = (isDragging, draggableStyle, item) => ({
    // userSelect: "none",
    // change background colour if dragging and if target overdue
    // background: isDragging ? "rgba(0, 0, 0, 0.1)" : "transparent",
    // "rgba(68, 73, 80, 0.5)"
    backgroundColor:
      item.id === itemToHighlight
        ? theme.palette.primary.light
        : isDragging
        ? "rgba(0, 0, 0, 0.1)"
        : "transparent",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  return (
    <Draggable
      draggableId={item.id}
      index={index}
      isDragDisabled={true} // TODO - Delete once drag n drop feat is done
    >
      {(provided, snapshot) => (
        <li
          id={`goal-${item.id}`}
          className="item-container"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
            item
          )}
        >
          <div className="item">
            <Checkbox
              color={
                item.isPartiallyDone
                  ? "tertiary"
                  : item.isDone
                  ? "primary"
                  : "error"
              }
              sx={{
                // This is to override gray color when disabled
                "&.Mui-disabled": {
                  color: item.isPartiallyDone
                    ? theme.palette.tertiary.main
                    : item.isDone
                    ? theme.palette.primary.main
                    : theme.palette.error.main,
                },
              }}
              onChange={() =>
                moment(date) <= moment().endOf("day") ? handleCheck(item) : null
              }
              checked={item.isDone}
              checkedIcon={<FaCheckCircle size={24} />}
              icon={
                item.isPartiallyDone ? (
                  <FaHourglassHalf
                    size={24}
                    style={{
                      color: theme.palette.tertiary.main,
                    }}
                  />
                ) : isSubmitted ? (
                  <FaTimes
                    size={24}
                    style={{
                      color: theme.palette.error.main,
                    }}
                  />
                ) : (
                  <FaRegCircle
                    size={24}
                    style={{
                      color: theme.palette.gray.main,
                    }}
                  />
                )
              }
              // How to conditionally set props (in this case was used to set Times or neither)
              // {...(isSubmitted
              //   ? {
              //       icon: (
              //         <FaTimes
              //           size={24}
              //           style={{
              //             color: theme.palette.error.main,
              //           }}
              //         />
              //       ),
              //     }
              //   : {})}
              disabled={isSubmitted}
            />

            <div
              className="item-name-container"
              onClick={() =>
                currentDevice === Devices.PHONE
                  ? !isSubmitted
                    ? navigate(
                        isGoal
                          ? "goal/edit"
                          : !navigateToTargetsScreen // This is to navigate to targets screen from goals screen > current month targets
                          ? "edit"
                          : "targets",
                        {
                          state: {
                            goalToEdit: {
                              ...item,
                              ...(item.date && {
                                date: moment(item.date)
                                  .utcOffset(0, true)
                                  .toString(),
                              }), // WARNING - Have to convert like this to pass a UTC serializable value within the history (moment obj is not serializable and therefore can't be passed as is, and toDate() uses incorrect timezone)
                              ...(item.startDate && {
                                startDate: moment(item.startDate)
                                  .utcOffset(0, true)
                                  .toString(),
                              }), // WARNING - Have to convert like this to pass a UTC serializable value within the history (moment obj is not serializable and therefore can't be passed as is, and toDate() uses incorrect timezone)
                              ...(item.endDate && {
                                endDate: moment(item.endDate)
                                  .utcOffset(0, true)
                                  .toString(),
                              }), // WARNING - Have to convert like this to pass a UTC serializable value within the history (moment obj is not serializable and therefore can't be passed as is, and toDate() uses incorrect timezone)
                              ...(item.createdAt && {
                                createdAt:
                                  item.createdAt &&
                                  item.createdAt.toDate &&
                                  item.createdAt.toDate(),
                              }), // WARINING - These are passed as dates (timestamp is not serializable and therefore can't be passed as is)
                              ...(item.updatedAt && {
                                updatedAt:
                                  item.updatedAt &&
                                  item.updatedAt.toDate &&
                                  item.updatedAt.toDate(),
                              }), // WARINING - These are passed as dates (timestamp is not serializable and therefore can't be passed as is)
                              ...(item.originalDate && {
                                originalDate:
                                  item.originalDate &&
                                  item.originalDate.toDate &&
                                  item.originalDate.toDate(),
                              }), // WARINING - These are passed as dates (timestamp is not serializable and therefore can't be passed as is)
                            },
                            date: date?.toDate && date.toDate(),
                          },
                        }
                      )
                    : navigate("goal/view", {
                        state: {
                          goalToEdit: {
                            ...item,
                            ...(item.date && {
                              date: moment(item.date)
                                .utcOffset(0, true)
                                .toString(),
                            }), // WARNING - Have to convert like this to pass a UTC serializable value within the history (moment obj is not serializable and therefore can't be passed as is, and toDate() uses incorrect timezone)
                            ...(item.startDate && {
                              startDate: moment(item.startDate)
                                .utcOffset(0, true)
                                .toString(),
                            }), // WARNING - Have to convert like this to pass a UTC serializable value within the history (moment obj is not serializable and therefore can't be passed as is, and toDate() uses incorrect timezone)
                            ...(item.endDate && {
                              endDate: moment(item.endDate)
                                .utcOffset(0, true)
                                .toString(),
                            }), // WARNING - Have to convert like this to pass a UTC serializable value within the history (moment obj is not serializable and therefore can't be passed as is, and toDate() uses incorrect timezone)
                            ...(item.createdAt && {
                              createdAt:
                                item.createdAt &&
                                item.createdAt.toDate &&
                                item.createdAt.toDate(),
                            }), // WARINING - These are passed as dates (timestamp is not serializable and therefore can't be passed as is)
                            ...(item.updatedAt && {
                              updatedAt:
                                item.updatedAt &&
                                item.updatedAt.toDate &&
                                item.updatedAt.toDate(),
                            }), // WARINING - These are passed as dates (timestamp is not serializable and therefore can't be passed as is)
                            ...(item.originalDate && {
                              originalDate:
                                item.originalDate &&
                                item.originalDate.toDate &&
                                item.originalDate.toDate(),
                            }), // WARINING - These are passed as dates (timestamp is not serializable and therefore can't be passed as is)
                          },
                          date: date?.toDate && date.toDate(),
                        },
                      })
                  : null
              }
            >
              {!isSubmitted && currentDevice !== Devices.PHONE ? (
                <TextField
                  multiline
                  variant="standard"
                  color="secondary"
                  InputProps={{
                    style: {
                      color:
                        itemToCancelEdit?.id === item.id || !itemToCancelEdit
                          ? item.isDone
                            ? theme.palette.primary.main
                            : theme.palette.text.main
                          : theme.palette.gray.dark,
                    },
                    disableUnderline:
                      itemToCancelEdit?.id === item.id ||
                      document.activeElement.id ===
                        `item-name-input-${item.id}` ||
                      inputHoveredId === `item-name-input-${item.id}`
                        ? false
                        : true,
                  }}
                  sx={{
                    padding: 0.5,
                    // "&:hover": {
                    //   textDecoration:
                    //     document.activeElement.id ===
                    //     `item-name-input-${item.id}`
                    //       ? "none"
                    //       : "underline",
                    // },
                  }}
                  fullWidth
                  autoComplete="off"
                  required
                  id={`item-name-input-${item.id}`}
                  // value={`${item.name || "No title"}`}
                  value={`${item.name || "No title"}${
                    item?.originalDate
                      ? ` (from ${moment(item.originalDate).format("MMM D")})`
                      : ""
                  }`}
                  onChange={(e) => {
                    setSortedGoals(
                      [...sortedGoals].map((el) => {
                        if (el.id === item.id) {
                          return {
                            ...el,
                            name: e.target.value,
                          };
                        } else {
                          return { ...el };
                        }
                      })
                    );
                  }}
                  onFocus={() => {
                    itemToCancelEdit?.id !== item.id &&
                      cancelChanges(itemToCancelEdit);
                    setItemToCancelEdit(item);
                    setAllowNavigation(false);
                  }}
                  onKeyDown={(e) => downHandler(e, item)}
                  onMouseEnter={() =>
                    setInputHoveredId(`item-name-input-${item.id}`)
                  }
                  onMouseLeave={() => setInputHoveredId(null)}
                  // disabled={
                  //   itemToCancelEdit &&
                  //   itemToCancelEdit.id !== item.id
                  //     ? true
                  //     : false
                  // }
                />
              ) : (
                <span
                  style={{
                    color: item.isDone
                      ? theme.palette.primary.main
                      : theme.palette.text.main,
                  }}
                >
                  {item.name || "(No title)"}
                  {item?.originalDate
                    ? ` (${moment(item?.originalDate).format("MMM D")})`
                    : ""}
                </span>
              )}
            </div>
            {itemToCancelEdit?.id === item.id ? (
              <>
                <Button
                  variant="outlined"
                  color="secondary"
                  onMouseDown={(e) => {
                    saveChanges(item);
                    e.preventDefault();
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onMouseDown={(e) => {
                    cancelChanges(item);
                    e.preventDefault();
                  }}
                  sx={{
                    marginLeft: 1,
                  }}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <div className="item-icons-container">
                  <div className="icons-row">
                    {/* Yeah, that double ! is necessary */}
                    {item && !!item.description && (
                      <Link
                        to={
                          isSubmitted
                            ? isGoal
                              ? "goal/view"
                              : "view" // goal/edit is the main route (does not have '/goal')
                            : isGoal
                            ? "goal/edit"
                            : "edit" // goal/edit is the main route (does not have '/goal')
                        }
                        // Set all dates correctly with conditional to only send the ones the object has
                        state={{
                          goalToEdit: {
                            ...item,
                            ...(item.date && {
                              date: moment(item.date)
                                .utcOffset(0, true)
                                .toString(),
                            }), // WARNING - Have to convert like this to pass a UTC serializable value within the history (moment obj is not serializable and therefore can't be passed as is, and toDate() uses incorrect timezone)
                            ...(item.startDate && {
                              startDate: moment(item.startDate)
                                .utcOffset(0, true)
                                .toString(),
                            }), // WARNING - Have to convert like this to pass a UTC serializable value within the history (moment obj is not serializable and therefore can't be passed as is, and toDate() uses incorrect timezone)
                            ...(item.endDate && {
                              endDate: moment(item.endDate)
                                .utcOffset(0, true)
                                .toString(),
                            }), // WARNING - Have to convert like this to pass a UTC serializable value within the history (moment obj is not serializable and therefore can't be passed as is, and toDate() uses incorrect timezone)
                            ...(item.createdAt && {
                              createdAt:
                                item.createdAt &&
                                item.createdAt.toDate &&
                                item.createdAt.toDate(),
                            }), // WARINING - These are passed as dates (timestamp is not serializable and therefore can't be passed as is)
                            ...(item.updatedAt && {
                              updatedAt:
                                item.updatedAt &&
                                item.updatedAt.toDate &&
                                item.updatedAt.toDate(),
                            }), // WARINING - These are passed as dates (timestamp is not serializable and therefore can't be passed as is)
                            ...(item.originalDate && {
                              originalDate:
                                item.originalDate &&
                                item.originalDate.toDate &&
                                item.originalDate.toDate(),
                            }), // WARINING - These are passed as dates (timestamp is not serializable and therefore can't be passed as is)
                          },
                          date: date?.toDate && date.toDate(),
                          autoFocusDescription: true,
                        }}
                      >
                        <IconButton>
                          <FaFileAlt
                            size={12}
                            style={{
                              color: theme.palette.gray.light,
                            }}
                          />
                        </IconButton>
                      </Link>
                    )}
                    {/* {item && !!item.reminder && (
                            <FaBell
                              size={12}
                              className="item-icon"
                              style={{
                                color: theme.palette.gray.light,
                              }}
                            />
                          )} */}
                    {!isSubmitted &&
                      item &&
                      !!item.recurrencePattern &&
                      item.recurrencePattern !== recurrencePatternEnum.NONE && (
                        <IconButton
                          onClick={() => {
                            setGoalToEdit(item);
                            setIsRepeatPatternPickerOverlayVisible(true);
                          }}
                        >
                          <FaSyncAlt
                            size={12}
                            style={{
                              color: theme.palette.gray.light,
                            }}
                          />
                        </IconButton>
                      )}
                  </div>
                  <div>
                    {/* {showDate && item.isDone && (
                        <label
                          style={{
                            color: theme.palette.text.main,
                          }}
                        >
                          {moment(item.date).year() === moment().year()
                            ? moment(item.date).format("MMM D")
                            : moment(item.date).format("MMM D, Y")}
                        </label>
                      )} */}
                  </div>
                </div>
                <div>
                  {currentDevice !== Devices.PHONE &&
                    (!isSubmitted ? (
                      <>
                        {!navigateToTargetsScreen ? (
                          <Link
                            to={
                              isGoal ? "goal/edit" : "edit" // goal/edit is the main route (does not have '/goal')
                            }
                            // Set all dates correctly with conditional to only send the ones the object has
                            state={{
                              goalToEdit: {
                                ...item,
                                ...(item.date && {
                                  date: moment(item.date)
                                    .utcOffset(0, true)
                                    .toString(),
                                }), // WARNING - Have to convert like this to pass a UTC serializable value within the history (moment obj is not serializable and therefore can't be passed as is, and toDate() uses incorrect timezone)
                                ...(item.startDate && {
                                  startDate: moment(item.startDate)
                                    .utcOffset(0, true)
                                    .toString(),
                                }), // WARNING - Have to convert like this to pass a UTC serializable value within the history (moment obj is not serializable and therefore can't be passed as is, and toDate() uses incorrect timezone)
                                ...(item.endDate && {
                                  endDate: moment(item.endDate)
                                    .utcOffset(0, true)
                                    .toString(),
                                }), // WARNING - Have to convert like this to pass a UTC serializable value within the history (moment obj is not serializable and therefore can't be passed as is, and toDate() uses incorrect timezone)
                                ...(item.createdAt && {
                                  createdAt:
                                    item.createdAt &&
                                    item.createdAt.toDate &&
                                    item.createdAt.toDate(),
                                }), // WARINING - These are passed as dates (timestamp is not serializable and therefore can't be passed as is)
                                ...(item.updatedAt && {
                                  updatedAt:
                                    item.updatedAt &&
                                    item.updatedAt.toDate &&
                                    item.updatedAt.toDate(),
                                }), // WARINING - These are passed as dates (timestamp is not serializable and therefore can't be passed as is)
                                ...(item.originalDate && {
                                  originalDate:
                                    item.originalDate &&
                                    item.originalDate.toDate &&
                                    item.originalDate.toDate(),
                                }), // WARINING - These are passed as dates (timestamp is not serializable and therefore can't be passed as is)
                              },
                              date: date?.toDate && date.toDate(),
                            }}
                          >
                            <IconButton>
                              <FaEllipsisH size={12} />
                            </IconButton>
                          </Link>
                        ) : (
                          <Link
                            to={"targets"}
                            onClick={() =>
                              dispatch(
                                changeCurrentScreen(ScreenNames.TARGETS_SCREEN)
                              )
                            }
                          >
                            <IconButton>
                              <FaEllipsisH size={12} />
                            </IconButton>
                          </Link>
                        )}
                        {/* <IconButton
                              onClick={() =>
                                deleteGoalPressed(item)
                              }
                            >
                              <FaTrash className="delete-icon" />
                            </IconButton> */}
                      </>
                    ) : (
                      <Link
                        to={
                          isGoal ? "goal/view" : "view" // goal/edit is the main route (does not have '/goal')
                        }
                        state={{
                          goalToEdit: {
                            ...item,
                            ...(item.date && {
                              date: moment(item.date)
                                .utcOffset(0, true)
                                .toString(),
                            }), // WARNING - Have to convert like this to pass a UTC serializable value within the history (moment obj is not serializable and therefore can't be passed as is)
                            ...(item.startDate && {
                              startDate: moment(item.startDate)
                                .utcOffset(0, true)
                                .toString(),
                            }), // WARNING - Have to convert like this to pass a UTC serializable value within the history (moment obj is not serializable and therefore can't be passed as is, and toDate() uses incorrect timezone)
                            ...(item.endDate && {
                              endDate: moment(item.endDate)
                                .utcOffset(0, true)
                                .toString(),
                            }), // WARNING - Have to convert like this to pass a UTC serializable value within the history (moment obj is not serializable and therefore can't be passed as is, and toDate() uses incorrect timezone)
                            ...(item.createdAt && {
                              createdAt:
                                item.createdAt &&
                                item.createdAt.toDate &&
                                item.createdAt.toDate(),
                            }), // WARINING - These are passed as dates (timestamp is not serializable and therefore can't be passed as is)
                            ...(item.updatedAt && {
                              updatedAt:
                                item.updatedAt &&
                                item.updatedAt.toDate &&
                                item.updatedAt.toDate(),
                            }), // WARINING - These are passed as dates (timestamp is not serializable and therefore can't be passed as is)
                            ...(item.originalDate && {
                              originalDate:
                                item.originalDate &&
                                item.originalDate.toDate &&
                                item.originalDate.toDate(),
                            }), // WARINING - These are passed as dates (timestamp is not serializable and therefore can't be passed as is)
                          },
                          date: date?.toDate && date.toDate(),
                        }}
                      >
                        <IconButton>
                          <FaEye size={12} />
                        </IconButton>
                      </Link>
                    ))}
                </div>
              </>
            )}
            <div
              className="item-tag"
              style={{
                backgroundColor: item.tag
                  ? tagColors[item.tag - 1].color
                  : "transparent",
              }}
            />
          </div>
        </li>
      )}
    </Draggable>
  );
};

const equal = (prev, next) => {
  // This conditional should have every visual (relevant) property of the obj and its id
  return (
    prev.item.id === next.item.id &&
    prev.item?.name === next.item?.name &&
    prev.item?.isDone === next.item?.isDone &&
    prev.item?.isPartiallyDone === next.item?.isPartiallyDone &&
    prev.item?.tag === next.item?.tag &&
    prev.item?.description === next.item?.description &&
    prev.item?.recurrencePattern === next.item?.recurrencePattern &&
    prev.itemToHighlight === next.itemToHighlight &&
    prev.inputHoveredId !== `item-name-input-${next.item.id}` &&
    next.inputHoveredId !== `item-name-input-${next.item.id}` &&
    prev.itemToCancelEdit?.id !== next.item.id &&
    next.itemToCancelEdit?.id !== next.item.id &&
    prev.isSubmitted === next.isSubmitted
  );
};

export default React.memo(Goal, equal);
