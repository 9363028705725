import { firestore, auth } from "../firebase";
import moment from "moment";
import "moment-timezone";
import { updateLastTimeActive } from "./users";

import { getTimestamp } from "../helper";

import { captureException } from "@sentry/react";

export const addRecurrentGoal = async (recurrentGoalReceived, id = null) => {
  try {
    const userUid = auth.currentUser.uid;

    const {
      originalGoalId,
      fromInbox,
      fromRecurrentGoal,
      isPartiallyDone,
      ...recurrentGoalDestructured
    } = recurrentGoalReceived; // Destructuring

    const recurrentGoal = {
      ...recurrentGoalDestructured,
      startDate: getTimestamp(
        moment(recurrentGoalDestructured.startDate)
          .startOf("day")
          .utcOffset(0, true)
      ),
      ...(recurrentGoalDestructured.endDate && {
        endDate: getTimestamp(
          moment(recurrentGoalDestructured.endDate)
            .endOf("day")
            .utcOffset(0, true)
        ),
      }),
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // WARNING - .doc(null) is not working, that's why conditional
    let recurrentGoalRef = null;
    if (id) {
      recurrentGoalRef = firestore
        .collection("recurrentGoals")
        .doc(userUid)
        .collection("recurrentGoals")
        .doc(id);
    } else {
      recurrentGoalRef = firestore
        .collection("recurrentGoals")
        .doc(userUid)
        .collection("recurrentGoals")
        .doc();
    }
    batch.set(recurrentGoalRef, recurrentGoal, { merge: true });

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException(
        "Error while commiting recurrent goal transaction",
        error
      );
      console.error(
        "Error while commiting recurrent goal transaction: " + error
      );
    });

    updateLastTimeActive();

    return recurrentGoalRef.id;
  } catch (error) {
    captureException("Error while adding recurrent goal", error);
    console.error("Error while adding recurrent goal: " + error);
  }
};

export const updateRecurrentGoal = (
  recurrentGoalReceived,
  originalId = null
) => {
  try {
    const userUid = auth.currentUser.uid;

    const {
      id,
      originalGoalId,
      fromInbox,
      isDone,
      date,
      isPartiallyDone,
      fromRecurrentGoal,
      ...recurrentGoalDestructured
    } = recurrentGoalReceived; // Destructuring

    const recurrentGoal = {
      ...recurrentGoalDestructured,
      startDate: getTimestamp(
        moment(recurrentGoalDestructured.startDate)
          .startOf("day")
          .utcOffset(0, true)
      ),
      ...(recurrentGoalDestructured.endDate && {
        endDate: getTimestamp(
          moment(recurrentGoalDestructured.endDate)
            .endOf("day")
            .utcOffset(0, true)
        ),
      }),
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Update goal
    const recurrentGoalRef = firestore
      .collection("recurrentGoals")
      .doc(userUid)
      .collection("recurrentGoals")
      .doc(originalId || fromRecurrentGoal || id);
    batch.set(recurrentGoalRef, recurrentGoal, { merge: true });

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException(
        "Error while commiting recurrent goal update transaction",
        error
      );
      console.error(
        "Error while commiting recurrent goal update transaction: " + error
      );
    });
  } catch (error) {
    captureException("Error while updating recurrent goal", error);
    console.error("Error while updating recurrent goal: " + error);
  }
};

export const deleteRecurrentGoal = (recurrentGoalId) => {
  try {
    const userUid = auth.currentUser.uid;

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Delete goal
    const recurrentGoalRef = firestore
      .collection("recurrentGoals")
      .doc(userUid)
      .collection("recurrentGoals")
      .doc(recurrentGoalId);

    if (recurrentGoalRef) {
      batch.delete(recurrentGoalRef);

      // ---- Finish transaction ----
      batch.commit().catch((error) => {
        captureException(
          "Error while commiting delete recurrent goal transaction",
          error
        );
        console.error(
          "Error while commiting delete recurrent goal transaction: " + error
        );
      });
    }
  } catch (error) {
    captureException("Error while deleting recurrent goal", error);
    console.error("Error while deleting recurrent goal: " + error);
  }
};

export const undoDeleteRecurrentGoal = (recurrentGoalReceived) => {
  try {
    const {
      id,
      originalGoalId,
      fromInbox,
      fromRecurrentGoal,
      date,
      ...recurrentGoalDestructured
    } = recurrentGoalReceived; // Destructuring

    addRecurrentGoal(recurrentGoalDestructured, id);
  } catch (error) {
    captureException("Error while undoing delete recurrent goal", error);
    console.error("Error while undoing delete recurrent goal: " + error);
  }
};
