import firebase from "firebase/compat/app";
import { firestore, auth } from "../firebase";
import { toast } from "react-toastify";
import moment from "moment";
import "moment-timezone";

import { toggleInboxDone } from "./inboxes";
import { updateLastTimeActive, updateLastTimeGoalsInteraction } from "./users";

import { getTimestamp } from "../helper";

import { captureException } from "@sentry/react";

export const addGoal = async (goalReceived, id = null, showToast = false) => {
  try {
    const userUid = auth.currentUser.uid;

    const goal = {
      ...goalReceived,
      date: getTimestamp(moment(goalReceived.date).utcOffset(0, true)),
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // WARNING - .doc(null) is not working, that's why conditional
    let goalRef = null;
    if (id) {
      goalRef = firestore
        .collection("goals")
        .doc(userUid)
        .collection("goals")
        .doc(id);
    } else {
      goalRef = firestore
        .collection("goals")
        .doc(userUid)
        .collection("goals")
        .doc();
    }
    batch.set(goalRef, { ...goal, isDone: goal.isDone || false });

    // Increment goalsTotal
    const counterRef = firestore.collection("counters").doc(userUid);
    batch.set(
      counterRef,
      {
        goalsTotal: firebase.firestore.FieldValue.increment(1),
        goalsDone: firebase.firestore.FieldValue.increment(goal.isDone ? 1 : 0),
      },
      { merge: true }
    );

    updateLastTimeActive();
    updateLastTimeGoalsInteraction();

    // ---- Finish transaction ----

    // OLD: return await (only runs if internet)
    batch.commit().catch((error) => {
      captureException("Error while commiting goal transaction", error);
      console.error("Error while commiting goal transaction: " + error);
    });

    if (showToast) {
      toast.success("Goal added!");
    }

    return goalRef.id;
  } catch (error) {
    captureException("Error while adding goal", error);
    console.error("Error while adding goal: " + error);
  }
};

export const updateGoal = (goalReceived) => {
  try {
    const userUid = auth.currentUser.uid;

    const { id, originalGoalId, ...goalDestructured } = goalReceived; // Destructuring

    const goal = {
      ...goalDestructured,
      date: getTimestamp(moment(goalDestructured.date).utcOffset(0, true)),
      ...(goalDestructured.startDate && {
        startDate: getTimestamp(
          moment(goalDestructured.startDate).startOf("day").utcOffset(0, true)
        ),
      }),
      ...(goalDestructured.endDate && {
        endDate: getTimestamp(
          moment(goalDestructured.endDate).endOf("day").utcOffset(0, true)
        ),
      }),
      ...(goalDestructured.originalDate && {
        originalDate: getTimestamp(
          moment(goalDestructured.originalDate).endOf("day").utcOffset(0, true)
        ),
      }),
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Update goal
    const goalRef = firestore
      .collection("goals")
      .doc(userUid)
      .collection("goals")
      .doc(id);
    batch.set(goalRef, goal, { merge: true });

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException("Error while commiting goal update transaction", error);
      console.error("Error while commiting goal update transaction: " + error);
    });
  } catch (error) {
    captureException("Error while updating goal", error);
    console.error("Error while updating goal: " + error);
  }
};

// export const sortGoals = async (goals) => {
//   try {
//     const userUid = auth.currentUser.uid;

//     // ---- Start transaction ----
//     const batch = firestore.batch();

//     goals.forEach((goal) => {
//       const { id, ...goalWithoutId } = goal;

//       // Update recurrentGoal or goal
//       const goalRef = firestore
//         .collection(!goal.date ? "recurrentGoals" : "goals")
//         .doc(userUid)
//         .collection(!goal.date ? "recurrentGoals" : "goals")
//         .doc(id);
//       batch.set(goalRef, goalWithoutId, { merge: true });
//     });

//     // ---- Finish transaction ----
//     await batch.commit().catch((error) => {
//       console.error("Error while commiting goal sort/update transaction: ", error);
//     });
//   } catch (error) {
//     console.error("Error while sort/update goal: ", error);
//   }
// };

export const deleteGoal = (goal) => {
  try {
    const userUid = auth.currentUser.uid;

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Delete goal
    const goalRef = firestore
      .collection("goals")
      .doc(userUid)
      .collection("goals")
      .doc(goal.id);
    batch.delete(goalRef);

    // Decrement goalsTotal and goalsDone
    const counterRef = firestore.collection("counters").doc(userUid);
    batch.set(
      counterRef,
      {
        goalsTotal: firebase.firestore.FieldValue.increment(-1),
        goalsDone: firebase.firestore.FieldValue.increment(
          goal.isDone ? -1 : 0
        ),
      },
      { merge: true }
    );

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException("Error while commiting delete goal transaction", error);
      console.error("Error while commiting delete goal transaction: " + error);
    });
  } catch (error) {
    captureException("Error while deleting goal", error);
    console.error("Error while deleting goal: " + error);
  }
};

export const deleteGoalComingFromInbox = async (inboxId) => {
  try {
    const userUid = auth.currentUser.uid;

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Delete goal
    const goalRef = await firestore
      .collection("goals")
      .doc(userUid)
      .collection("goals")
      .where("fromInbox", "==", inboxId)
      .get();

    goalRef.forEach((doc) => {
      batch.delete(doc.ref);
    });

    // Decrement goalsTotal and goalsDone
    const counterRef = firestore.collection("counters").doc(userUid);
    batch.set(
      counterRef,
      {
        goalsTotal: firebase.firestore.FieldValue.increment(-1),
        goalsDone: firebase.firestore.FieldValue.increment(-1),
      },
      { merge: true }
    );

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException("Error while commiting delete goal transaction", error);
      console.error("Error while commiting delete goal transaction: " + error);
    });
  } catch (error) {
    captureException("Error while deleting goal", error);
    console.error("Error while deleting goal: " + error);
  }
};

export const toggleGoalDone = (goal) => {
  try {
    const userUid = auth.currentUser.uid;

    // ---- Start transaction ----
    const batch = firestore.batch();

    if (goal.fromInbox) {
      toggleInboxDone(goal);
    } else {
      // Change goal isDone value
      const goalRef = firestore
        .collection("goals")
        .doc(userUid)
        .collection("goals")
        .doc(goal.id);
      batch.set(
        goalRef,
        {
          isDone: goal.isDone,
          isPartiallyDone: goal.isPartiallyDone,
        },
        { merge: true }
      );
    }

    // Update goalsDone counter
    if (!goal.fromInbox) {
      const counterRef = firestore.collection("counters").doc(userUid);
      batch.set(
        counterRef,
        {
          goalsDone: firebase.firestore.FieldValue.increment(
            goal.isDone ? 1 : -1
          ),
        },
        { merge: true }
      );
    } else {
      // Don't update goalsDone counter if goal comes from inbox and is beign unchecked,
      // as it will be updated later when the goal is actually deleted
      if (goal.isDone) {
        const counterRef = firestore.collection("counters").doc(userUid);
        batch.set(
          counterRef,
          {
            goalsDone: firebase.firestore.FieldValue.increment(1),
          },
          { merge: true }
        );
      }
    }

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException("Error while commiting toggle goal transaction", error);
      console.error("Error while commiting toggle goal transaction: " + error);
    });

    updateLastTimeActive();
    updateLastTimeGoalsInteraction();
  } catch (error) {
    captureException("Error while toggling goal", error);
    console.error("Error while toggling goal: " + error);
  }
};

export const undoDeleteGoal = (goal) => {
  try {
    const { id, originalGoalId, ...goalWithoutId } = goal; // Destructuring
    addGoal(goalWithoutId, id);
  } catch (error) {
    captureException("Error while undoing delete goal", error);
    console.error("Error while undoing delete goal: " + error);
  }
};

export const instanceGoalFromRecurrentGoal = (recurrentGoalReceived) => {
  try {
    const userUid = auth.currentUser.uid;

    const { id, originalGoalId, ...goalDestructured } = {
      ...recurrentGoalReceived,
      fromRecurrentGoal: recurrentGoalReceived.id,
    }; // Destructuring

    const goal = {
      ...goalDestructured,
      date: getTimestamp(moment(goalDestructured.date).utcOffset(0, true)),
      ...(goalDestructured.startDate && {
        startDate: getTimestamp(
          moment(goalDestructured.startDate).startOf("day").utcOffset(0, true)
        ),
      }),
      ...(goalDestructured.endDate && {
        endDate: getTimestamp(
          moment(goalDestructured.endDate).endOf("day").utcOffset(0, true)
        ),
      }),
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    const goalRef = firestore
      .collection("goals")
      .doc(userUid)
      .collection("goals")
      .doc();
    batch.set(goalRef, goal);

    // Increment goalsTotal
    const counterRef = firestore.collection("counters").doc(userUid);
    batch.set(
      counterRef,
      {
        goalsTotal: firebase.firestore.FieldValue.increment(1),
        goalsDone: firebase.firestore.FieldValue.increment(goal.isDone ? 1 : 0),
      },
      { merge: true }
    );

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException("Error while commiting goal transaction", error);
      console.error("Error while commiting goal transaction: " + error);
    });
  } catch (error) {
    captureException("Error while adding goal", error);
    console.error("Error while adding goal: " + error);
  }
};

export const getOriginalGoalId = async (goalId) => {
  try {
    const userUid = auth.currentUser.uid;

    return await firestore
      .collection("goals")
      .doc(userUid)
      .collection("goals")
      .doc(goalId)
      .get()
      .then(async (query) => {
        let originalGoalId = null;

        if (query.exists) {
          originalGoalId = query.data().id || query.id;
        }

        return originalGoalId;
      });
  } catch (error) {
    captureException("Failed to get goal id", error);
    console.error("Failed to get goal id: " + error);
  }
};

export const IsGoalsCollectionEmpty = async () => {
  try {
    const userUid = auth.currentUser.uid;

    return await firestore
      .collection("goals")
      .doc(userUid)
      .collection("goals")
      .limit(1)
      .get()
      .then(async (query) => {
        return query.empty;
      });
  } catch (error) {
    captureException("Failed to check if goal's collection is empty", error);
    console.error("Failed to check if goal's collection is empty: " + error);
  }
};
